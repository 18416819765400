import "./Form.css";
import { DateTime, Duration } from "luxon";
import React, { useState, useRef, MutableRefObject } from "react";

function Form() {
  const [coffeeStr, setCoffeeStr] = useState("30");

  const [timer, setTimer] = useState<Duration | null>(null);
  const [ratioStr, setRatioStr] = useState("65");
  const countRef: MutableRefObject<NodeJS.Timer | null> = useRef(null);

  const handleStart = () => {
    console.log("start");
    const start = DateTime.now();
    if (countRef.current != null) {
      clearInterval(countRef.current);
    }
    countRef.current = setInterval(() => {
      const dt = DateTime.now().diff(start);
      setTimer(dt);
    }, 100);
  };

  const handleReset = () => {
    if (typeof countRef.current === "number") clearInterval(countRef.current);
    setTimer(null);
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCoffeeStr(e.target.value);
  };

  const onChangeRatio = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRatioStr(e.target.value);
  };

  const coffee = (function () {
    const parsed = parseFloat(coffeeStr);
    if (Number.isNaN(parsed)) {
      return 0.0;
    }
    return parsed;
  })();

  const ratio = (function () {
    const parsed = parseFloat(ratioStr);
    if (Number.isNaN(parsed)) {
      return 0.0;
    }
    return parsed;
  })();

  const water = Math.round(((coffee * 1000.0) / ratio) * 10) / 10;

  function clamp(
    time: number,
    startTime: number,
    endTime: number,
    startValue: number,
    endValue: number
  ): number {
    if (time > endTime) {
      return endValue;
    }
    const ratio = (time - startTime) / (endTime - startTime);
    const value = startValue + ratio * (endValue - startValue);
    return Math.round(value);
  }

  function getWeight(time: Duration | null): number {
    const seconds = time ? time.as("seconds") : 0.0;
    if (seconds < 45) {
      return coffee * 2.0;
    } else if (seconds < 75) {
      return clamp(seconds, 45, 75, coffee * 2.0, water * 0.6);
    } else {
      return clamp(seconds, 75, 105, water * 0.6, water);
    }
  }

  const weight = getWeight(timer);

  const formatTime = () => {
    console.log({ time: timer?.milliseconds, seconds: timer?.seconds });
    return timer?.toFormat("mm:ss");
  };

  return (
    <div className="Form">
      <div className="container">
        <h1>Ultimate V60</h1>

        <div className="fields">
          <label htmlFor="coffee">Grams of coffee</label>
          <div className="textInput">
            <input
              name="coffee"
              type="number"
              onChange={onChange}
              value={coffeeStr}
            />
          </div>
          <label htmlFor="ratio">Grams of coffee pr. liter</label>
          <div className="textInput">
            <input
              name="ratio"
              type="number"
              value={ratioStr}
              onChange={onChangeRatio}
            />
          </div>

          <label htmlFor="water-45">Water after 0:45 sec</label>
          <div className="textInput">
            <input
              name="water-45"
              type="number"
              value={getWeight(Duration.fromObject({ seconds: 45 }))}
              readOnly
            />
          </div>

          <label htmlFor="water-75">Water after 1:15</label>
          <div className="textInput">
            <input
              name="water-75"
              type="number"
              value={getWeight(Duration.fromObject({ seconds: 75 }))}
              readOnly
            />
          </div>
          <label htmlFor="water">Water after 1:45</label>
          <div className="textInput">
            <input name="water" type="text" value={water} readOnly />
          </div>
        </div>
      </div>
      <div className="buttons">
        <button onClick={handleStart}>Start</button>
        <button onClick={handleReset}>Reset</button>
      </div>
      <div className="timerValueBlock">
        <span className="timerValue">{formatTime()}</span>
        <span className="spacer" />
        <span className="timerValue">
          <span className="weight">{weight}</span> grams
        </span>
      </div>
    </div>
  );
}

export default Form;
